.default-text {
  @include Text-font-style($white, $PrimaryFont, normal, 400, 16px, 17px);
}

.first-text {
  @include Text-font-style($bluesky, $PrimaryFont, normal, 400, 16px, 17.41px);
}
.first-text.type-24 {
  text-decoration-line: underline;
}

.default-text.type-erro {
  line-height: 17px;
  font-size: 16px;
  font-weight: 300;
  color: $red;
}
.default-text.type-1 {
  line-height: 41.34px;
  font-size: 38px;
  font-weight: 800;
  text-transform: uppercase;
}

.default-text.type-2 {
  line-height: 22px;
  font-size: 20px;
}

.default-text.type-0 {
  line-height: 41.34px;
  font-size: 38px;
  font-weight: 900;
}

.default-text.type-3 {
  line-height: 17px;
  font-size: 16px;
  font-weight: 600;
}

.default-text.type-4 {
  line-height: 28px;
  font-size: 26px;
  font-weight: 500;
}

.default-text.type-5 {
  line-height: 22px;
  font-size: 16px;
  font-weight: 300;
}

.default-text.type-6 {
  line-height: 26px;
  font-size: 24px;
  font-weight: 400;
}

.default-text.type-7 {
  line-height: 26px;
  font-size: 24px;
  font-weight: 500;
}
.default-text.type-error {
  line-height: 16px;
  font-size: 14px;
  font-weight: 200;
  color: $red;
}

.default-text.type-8 {
  line-height: 17px;
  font-size: 16px;
  font-weight: 400;
}

.default-text.type-9 {
  line-height: 21px;
  font-size: 17.3097px;
  font-weight: 400;
}

.default-text.type-10 {
  line-height: 17px;
  font-size: 16px;
  font-weight: 200;
}

.default-text.type-11 {
  line-height: 17.39px;
  font-size: 16px;
  font-weight: 200;
}

.default-text.type-12 {
  line-height: 17.41px;
  font-size: 16px;
  font-weight: 400;
}

.default-text.type-14 {
  line-height: 17px;
  font-size: 16px;
  font-weight: 600;
}

.default-text.type-15 {
  line-height: 28px;
  font-size: 26px;
  font-weight: 500;
}

.default-text.type-16 {
  line-height: 26px;
  font-size: 23.9809px;
  font-weight: 400;
}

.default-text.type-17 {
  line-height: 15px;
  font-size: 13.9777px;
  font-weight: 400;
}

.default-text.type-88 ,
.default-text.type-18 {
  line-height: 17px;
  font-size: 15.9873px;
  font-weight: 400;
}
.default-text.type-18 {
  text-decoration-line: underline;
}

.default-text.type-19 {
  line-height: 21px;
  font-size: 17.3097px;
  font-weight: 400;
}

.first-text.type-13 {
  line-height: 17.41px;
  font-size: 16px;
  font-weight: 400;
}

.first-text.type-20 {
  line-height: 36.2px;
  font-size: 25.8537px;
  font-weight: 700;
}

.first-text.type-21 {
  line-height: 18px;
  font-size: 24px;
  font-weight: 200;
}

.first-text.type-22 {
  line-height: 26px;
  font-size: 24px;
  font-weight: 300;
}

.first-text.type-23 {
  line-height: 17px;
  font-size: 16px;
  font-weight: 500;
}
.default-text.type-25 {
  line-height: 41.34px;
  font-size: 38px;
  font-weight: 800;
}
.default-text.type-26 {
  font-size: 100px;
  font-weight: 600;
  line-height: 109px;
  text-transform: uppercase;
}

.default-text.type-grey1 {
  font-weight: 300;
  line-height: 22px;
  color: $darkgrey;
  text-decoration-line: underline;
}

.default-text.type-grey2 {
  color: $darkgrey;
}
.default-text.type-black {
  color: $dark;
  line-height: 17.39px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
.description-title{
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.font-weight-400{
  font-weight: 400 !important;
}