$PrimaryFont: "Archivo", sans-serif;
$SecondaryFont: "Inter", sans-serif;
$white: #fff;
$dark: #000;
$error: #ec2323;
$success: #029627;
$warning: yellow;
$grey: #8c98a4;
$blue: #477ff6;
$darkgrey: #d8d4d4;
$bluesky: #78ecff;
$pink: #e594f9;
$purple: #b80fbb;
$purpleop: #9800be;
$green: #007a8d;
$gradient: linear-gradient(269.99deg, #e594f9 -8.15%, #78ecff 110.29%);
$shadow: rgba(216, 250, 255, 0.53);
$gray: #b0eaf375;
$greymodal: #b0eaf3;
$red: #ff0000;
$greey: #808080;
$white03: #ffffff4d;
$black-transparent:rgba(0, 0, 0, 0.5);
