.default-btn {
  @include Text-font-style($dark, $PrimaryFont, normal, 600, 16px, 17px);
  @include Button-color($dark, $gradient, null, none, $gradient);
  box-sizing: border-box;
  border-radius: 16.9865px;
  display: flex;
  border: 0;
  background: rgb(229, 148, 249);
  background: linear-gradient(
    269deg,
    rgba(229, 148, 249, 1) 0%,
    rgba(120, 217, 255, 1) 62%
  );
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 65px;
  box-shadow: 0px 0px 36.9705px $shadow;
}

.default-btn:disabled,
.default-btn[disabled] {
  border: 0;
  background-color: $darkgrey;
  color: $white;
}

.default-btn.type-2,
.default-btn.type-3 {
  color: $white;
  background: none;
  border: 1.99841px solid $white;
}
.default-btn.type-2 {
  background-color: $dark;
  box-shadow: none;
}

.default-btn.type-3 {
  background-color: none;
  box-shadow: none;
}
.default-btn.type-4 {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: none;
}
.default-btn.type-5 {
  background-color: $dark;
  box-shadow: none;
}
.default-btn.type-6 {
  height: 74.91880798339844px;
  width: 74.9402847290039px;
  border-radius: 92px;
}
.btn-add-event {
  bottom: 43px;
  right: 12px;
  z-index: 100;
}

.not-disabled.active,
.not-disabled:focus,
.not-disabled:hover {
  background-color: $white;
  background: $white;
  color: #161d1f;
  span {
    color: #161d1f;
  }
  svg{
    fill: black !important;
    color: #161d1f;
  }
}
