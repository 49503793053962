@mixin createPaddingUtilities($utilityClass, $attribute) {
    @for $i from 1 through 100 {
        .#{$utilityClass}-#{$i} {
            #{$attribute}: #{$i}px;
        }
    }
}
@mixin createPaddingAxesUtilities($utilityClass, $attribute1, $attribute2) {
    @for $i from 1 through 100 {
        .#{$utilityClass}-#{$i} {
            #{$attribute1}: #{$i}px;
            #{$attribute2}: #{$i}px;
        }
    }
}
@include createPaddingUtilities("ds-p", "padding");
@include createPaddingUtilities("ds-pt", "padding-top");
@include createPaddingUtilities("ds-pb", "padding-bottom");
@include createPaddingUtilities("ds-pr", "padding-right");
@include createPaddingUtilities("ds-pl", "padding-left");
@include createPaddingAxesUtilities("ds-px", "padding-left", "padding-right");
@include createPaddingAxesUtilities("ds-py", "padding-bottom", "padding-top");
