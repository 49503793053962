.default-modal {
  border-radius: 17px;
  padding: 20px;
  .md-content {
    min-height: 468.75px;
    background: rgba(176, 234, 243, 0.3);
    backdrop-filter: blur(43px);
    flex-direction: column;
    justify-content: center;
  }
  .modale1 {
    min-height: 555.91px;
    background: rgba(176, 234, 243, 0.3);
    backdrop-filter: blur(43px);
    flex-direction: column;
    justify-content: center;
  }
  .modale-password {
    min-height: 349.96px;
    background: rgba(176, 234, 243, 0.3);
    backdrop-filter: blur(43px);
    flex-direction: column;
    justify-content: center;
  }
  .modale-resetpassword {
    min-height: 410.91px;
    background: rgba(176, 234, 243, 0.3);
    backdrop-filter: blur(43px);
    flex-direction: column;
    justify-content: center;
  }
  .modale-reply {
    min-height: 447.85px;
    background: rgba(176, 234, 243, 0.3);
    backdrop-filter: blur(43px);
    flex-direction: column;
    justify-content: center;
  }
  .modale-reply.small {
    min-height: 100px;
  }
}

.modal-close-icon {
  right: -15px;
}

.modal-body {
  padding: 0px;
}
.modal-dialog {
  width: auto;
}
