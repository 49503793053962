#root {
  width: 100%;
}
body {
  font-family: $PrimaryFont;
  background: $dark;
  background: #161d1f;
}
* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent !important;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  border-radius: 0px !important;
}

*::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 0px !important;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent !important;
  border-radius: 0px !important;
  border: 0px solid transparent !important;
}
@mixin createContainerUtilities($utilityClass, $attribute, $unit) {
  @for $i from 1 through 100 {
    .#{$utilityClass}-#{$i} {
      #{$attribute}: #{$i}#{$unit};
    }
  }
}
@include createContainerUtilities("ds-wp", "width", "px");
@include createContainerUtilities("ds-wv", "width", "vw");
@include createContainerUtilities("ds-w", "width", "%");
@include createContainerUtilities("ds-hp", "height", "px");
@include createContainerUtilities("ds-hv", "height", "vh");
@include createContainerUtilities("ds-h", "height", "%");
$colors: (
  white: (
    color: $white,
  ),
  dark: (
    color: $dark,
  ),
  error: (
    color: $error,
  ),
  success: (
    color: $success,
  ),
  warning: (
    color: $warning,
  ),
  grey: (
    color: $grey,
  ),
  blue: (
    color: $blue,
  ),
  pink: (
    color: $pink,
  ),
  purple: (
    color: $purple,
  ),
  bluesky: (
    color: $bluesky,
  ),
);
@mixin createUtilities($map, $secondaryKey, $utilityClass, $attribute) {
  @each $primaryKey, $val in $map {
    .#{$utilityClass}-#{$primaryKey} {
      #{$attribute}: #{map-get($val, $secondaryKey)};
    }
  }
}
@include createUtilities($colors, "color", "ds-bg", "background-color");
@include createUtilities($colors, "color", "ds-text", "color");
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.pointer {
  cursor: pointer;
}
.ds-w-max {
  width: max-content;
}
.flex-gr-1 {
  flex-grow: 1;
}
.overflow-y {
  overflow-y: auto;
}
.overflow-x {
  overflow-x: auto;
}
.transparent {
  color: transparent !important;
}
