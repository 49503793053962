.first-icon {
  width: 22.46px;
  height: 22.46px;
  left: 30.33%;
  right: 8.33%;
  top: 28.34%;
  bottom: 8.33%;
}

.first-icons {
  width: 22.46px;
  height: 22.46px;
  left: 30.33%;
  right: 8.33%;
  top: 22.34%;
  bottom: 8.33%;
}

.second-icon {
  width: 22.46px;
  height: 22.46px;
  left: 15.33%;
  right: 8.33%;
  top: 28.34%;
  bottom: 8.33%;
}

.third-icon {
  width: 22.46px;
  height: 22.46px;
  left: 24.33%;
  right: 8.33%;
  top: 28.34%;
  bottom: 8.33%;
}

.logo-header {
  height: 27.048580169677734px;
  width: 106.47032165527344px;
}
.menu-burger {
  height: 49.92041015625px;
  width: 49.92041015625px;
}
.icon_back {
  height: 5.985679626464849px;
  width: 11.971359252929698px;
}
.icon-calendar {
  position: absolute;
  height: 22.5000057220459px;
  width: 20.249998092651367px;
  left: 4.375px;
  top: 45px;
  z-index: -1;
}
.upload-icon {
    width: 30px;
    height: 30px;
    margin-left: 59%;
}
.add-icon {
  left: 327px;
  top: 779px;
}
.camera-icon {
  width: 30px;
  height: 30px;
}
.fill-white{
  fill:white !important;
}

.third-icon:focus,
.third-icon:hover{
  fill:black !important;
}