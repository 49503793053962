@mixin createMarginUtilities($utilityClass, $attribute) {
    @for $i from 1 through 100 {
        .#{$utilityClass}-#{$i} {
            #{$attribute}: #{$i}px;
        }
    }
}
@mixin createMarginAxesUtilities($utilityClass, $attribute1, $attribute2) {
    @for $i from 1 through 100 {
        .#{$utilityClass}-#{$i} {
            #{$attribute1}: #{$i}px;
            #{$attribute2}: #{$i}px;
        }
    }
}

@include createMarginUtilities("ds-m", "margin");
@include createMarginUtilities("ds-mt", "margin-top");
@include createMarginUtilities("ds-mb", "margin-bottom");
@include createMarginUtilities("ds-mr", "margin-right");
@include createMarginUtilities("ds-ml", "margin-left");
@include createMarginAxesUtilities("ds-mx", "margin-left", "margin-right");
@include createMarginAxesUtilities("ds-my", "margin-bottom", "margin-top");
.ds-mr-0{
    margin-right: 0px;
}