.invisible-date-picker {
  visibility: hidden;
  top: 0;
  left: 0;
  height: 0.0001px;
  width: 0.0001px;
}
.visible-date-picker {
  border-radius: 17px;
  background-color: rgba(255, 255, 255, 0.15);
  @include Text-font-style($greey, $PrimaryFont, normal, normal, 14px, 16px);
}
.visible-date-picker.value {
  color: $white;
  font-size: 14px;
  line-height: 16px;
}
.visible-date-picker-invalid {
  border: 2px solid $red;
}
.react-datepicker-popper {
  z-index: 3;
  width: max-content;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px !important;
  .react-datepicker {
    width: max-content;
  }
}
.react-datepicker-wrapper {
  height: 0px !important;
}
.ds-single-date-picker {
  border: none !important;
  border-radius: 6px !important;
  background-color: $white;
  .react-datepicker__month-container {
    border: none !important;
  }
  .react-datepicker__header--custom {
    border: none !important;
    background-color: $white !important;
  }
  .react-datepicker__month {
    margin: 0.4rem 0px;
  }
  .react-datepicker__day-name {
    color: $grey !important;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    width: 32px;
    height: 24px;
    line-height: 18px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    white-space: nowrap;
    cursor: pointer;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    color: $dark;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--today:focus,
  .react-datepicker__day--today:hover,
  .react-datepicker__day--today {
    border: none !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: $blue !important;
  }
  .react-datepicker__week div {
    padding: 0px;
    margin: 0.1px;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    line-height: 24px;
    font-size: 12px;
    border-radius: 2px;
    white-space: nowrap;
    cursor: pointer;
    width: 2.3875rem;
    height: 2.3375rem;
    font-size: 0.875rem;
    line-height: 1.9375rem;
    font-weight: normal !important;
    padding-top: 2.3px;
    background-color: #f9f9f9;
  }
  .react-datepicker__week div:hover {
    color: #377dff;
    background-color: rgba(33, 122, 239, 0.1);
  }
  .react-datepicker__day--outside-month {
    color: rgba(0, 0, 0, 0.1);
    background-color: $white !important;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__time-list,
  .react-datepicker__header,
  .react-datepicker__day {
    background-color: $white !important;
  }
  .react-datepicker__current-month {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .react-datepicker__navigation {
    top: 16px;
  }
}
