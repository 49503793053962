.flex {
    display: flex;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.center {
    justify-content: center;
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}
.justify-start {
    justify-content: flex-start;
}
.align-center {
    align-items: center;
}
.align-end {
    align-items: flex-end;
}
.align-start {
    align-items: flex-start;
}
.justify-between{
    justify-content: space-between;
}
.flex-grow-1{
    flex-grow: 1;
}
.flex-grow{
    flex-grow: 0;
}
.display-column-center{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}