.default-input,
.default-input:focus {
  height: 59.95px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16.9865px;
  border: none;
  color: $white;
  font-size: 16px;
  line-height: 18px;
}

.default-input.invalid {
  border: 2px solid $red;
}

.default-input.valid {
  border: 2px solid $success;
}
.default-input-file-invalid {
  border: 2px solid $red;
}
.date-picker {
  height: 65.94744873046875px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16.9865px;
  border: none;
  color: $white;
  font-size: 13px;
  line-height: 18px;
}
.date-picker.invalid {
  border: 2px solid $red;
}

.text-area,
.text-area:focus {
  height: 95.92px;
  background: rgba(255, 255, 255, 0.15);
  color: $white;
  border-radius: 16.9865px;
  flex: none;
  border: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 8px 18px;
  outline: 0px !important;
  font-size: 16px;
  line-height: 18px;
}
.text-area:focus {
  box-sizing: border-box;
  resize: none;
}
.text-area.invalid,
.text-area.invalid:focus {
  border: 2px solid $red;
}
.text-area.valid,
.text-area.valid:focus {
  border: 2px solid $success;
}
.container-options-maps {
  background: $dark;
  border-radius: 10px;
  z-index: 100;
  div {
    min-height: 32px;
    padding: 6px 12px;
  }
}
