.default-card {
  height: 218.88px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 17.9857px 14.9881px 16.9865px rgba(0, 0, 0, 0.25);
  border-radius: 25.9793px;
  flex: none;
  order: 0;
}
.image-card {
  width: 100%;
  height: 149.88px;
  border-radius: 16.9865px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  object-fit: cover;
}
.cercle-plus {
  height: 41.96656036376953px;
  width: 41.96656036376953px;
  border-radius: 40.96735763549805px;
  background: rgba(255, 255, 255, 0.3);
}
.recangle {
  height: 64px;
  order: 1;
  align-self: stretch;
}

.card-type1 {
  gap: 15.99px;
  width: 236.81px;
  height: 320.75px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 25.9793px;
  order: 0;
  margin: 0px !important;
}
.card-type2 {
  gap: 15.99px;
  height: 310.75px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 17.9857px 14.9881px 16.9865px rgba(0, 0, 0, 0.25);
  border-radius: 25.9793px;
  order: 0;
  margin: 0px !important;
}
.status {
  width: 107.98px;
  height: 24.99px;
  border-radius: 16.9865px;
  order: 2;
}
.rectangel-info {
  gap: 6px;
  min-height: 90.98px;
  order: 0;
}
.max-img {
  max-height: 239px;
}
.auto-img {
  height: auto;
  border-radius: 0px 0px 17px 17px;
}
.box-shadow {
  box-shadow: 0px 0px 10px $dark;
}
#caroussel-container {
  & > :nth-child(1) {
    padding-left: 20px;
  }
}

