.cercle {
  background: $black-transparent;
  border-radius: 40px;
}
.cercle-absolute {
  opacity: 0.72;
  background: linear-gradient(34.77deg, #e594f9 20.22%, #8fefff 66.81%, #8ef0ff 66.82%);
  box-shadow: inset 0px -18.8971px 16.0625px rgba(0, 0, 0, 0.26),
    inset -0.944856px 1.88971px 1.88971px rgba(255, 255, 255, 0.45),
    inset -1.88971px -0.944856px 5.66913px rgba(0, 0, 0, 0.46);
  border-radius: 50%;
}
.cercle-back-gr {
  width: 101px;
  height: 101px;
  left: 244px;
  top: 164px;
  filter: blur(4px);
}
.cercle-back-g {
  left: 102px;
  top: 225px;
  filter: blur(4px);
}
.cercle-back-gra {
  width: 173px;
  height: 173px;
  left: 58px;
  top: -58px;
  filter: blur(23px);
}
.logo {
  width: 282px;
  flex: none;
  order: 0;
  left: 10px;
  top: 10px;
}

.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: -1;
}
.z-index-100 {
  z-index: 1000005;
}

.cercle-purpule {
  width: 310px;
  height: 310px;
  top: -115px;
  right: 0;
  background: $purpleop;
  opacity: 0.8;
  filter: blur(179.71px);
}
.cercle-purpule-2 {
  width: calc(100vw - 30%);
  height: calc(100vw - 30%);
  top: -115px;
  right: 0;
  opacity: 0.8;
  background: $purpleop;
  filter: blur(79.71px);
}
.cercle-greay {
  width: calc(100vw - 20%);
  height: calc(100vw - 20%);
  left: -135px;
  top: 372px;
  background: $green;
  opacity: 0.27;
  filter: blur(179.326px);
}
.logo-visitr {
  height: 96px;
  img {
    height: 50%;
  }
}
.logo-reply {
  height: 96px;
  img {
    height: 50%;
  }
}
.react-multi-carousel-track li {
  width: 265px !important;
}

.upload-box {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 17px;
  min-height: 60px;
}

.ds-mt-20 {
  .react-switch-handle {
    width: 27.97px;
    height: 27.97px;
    background: $white;
    border: $white;
    box-shadow: 3.99682px 3.99682px 8.99283px rgba(0, 0, 0, 0.25);
  }
}
.image-upload {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40.9674px;
}
.image-upload input[type="file"] {
  opacity: 0;
  display: none;
}
.upload-box input[type="file"] {
  opacity: 0;
  display: none;
}
.details-img {
  height: 239px;
  filter: drop-shadow(18px 15px 17px rgba(0, 0, 0, 0.25));
  border-radius: 0px 0px 17px 17px;
}

.date-box {
  border-radius: 17px;
  background: rgba(255, 255, 255, 0.3);
}

.loc-box {
  min-height: 178.98px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 17px;
}
.map-card {
  position: relative !important;
  height: 102px !important;
  border-radius: 17px !important;
  div {
    border-radius: 17px;
  }
}
.description-box {
  height: 132px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 17px;
}
.description-box.open {
  min-height: 132px !important;
  height: auto;
}
.description-box-map {
  height: 30px;
}
.description-box-map.open {
  min-height: 40px !important;
  height: auto;
}
.ligne {
  opacity: 0.4;
  border: 1px solid #8d8d8d;
}

#style-1::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.15);
}

#style-1::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(255, 255, 255, 0.15);
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
}
.profile {
  width: 192px;
  height: 192px;
  border-radius: 50%;
  overflow: hidden;
}
.camera {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
}
.min-height {
  min-height: 349.96px;
}

.react-switch-handle {
  height: 40px;
  width: 40px;
  background: rgb(255, 255, 255);
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  transform: translateX(31px);
  top: 1px;
  outline: 0px;
  box-shadow: none;
  border: 0px;
  transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
}
.opacity-none {
  opacity: 0;
}
.display-none {
  display: none;
}
.opacity-one {
  opacity: 1;
}

.top-30 {
  top: 30%;
}
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  left: 10px;
  top: 50px;
  z-index: 1;
  -webkit-appearance: none;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  border: 2px solid $red;
}
.border-radius-50 {
  border-radius: 50%;
}
.position-bottom {
  bottom: 0;
  right: 0;
}
.right-0 {
  right: 0;
}
.gmnoprint {
  opacity: 0;
}
.profie-img {
  background: #e594f9;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}
.profieimg {
  background: #e594f9;
  border-radius: 50%;
  width: 192px;
  height: 192px;
}
.loc-box {
  button {
    display: none;
  }
}
.upper-case {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.camera-ch {
  bottom: 0px;
  right: 10px;
}
.camera-ph {
  bottom: 0px;
  right: 20px;
}
.white-space {
  white-space: nowrap;
}
.text-overflow {
  text-overflow: ellipsis;
  height: 100px;
  overflow: scroll;
  overflow-x: hidden;
}
@media only screen and (min-width: 768px) {
  .container-pages {
    max-width: 768px;
  }

  .cercle-purpule-2{
    width: 310px;
    height: 310px;
    top: -115px;
    right: 0;
    background: $purpleop;
    opacity: 0.8;
    filter: blur(179.71px);
  }
  .cercle-greay {
    width: 421px;
    height: 421px;
    filter: blur(179.326px);
  }
}
.shadow {
  box-shadow: 0px 0px 36.9705px $shadow;
}
.object-fit {
  object-fit: fill;
}
.object-cover {
  object-fit: cover;
}
.overflow-hidden {
  overflow: hidden !important;
}
.white-space {
  white-space: break-spaces;
}
.white-space-normal {
  white-space: normal;
}
.flesh-icon {
  height: 20px;
  width: 20px;
}
.detail-icon {
  height: 30px;
  width: 30px;
}
.pop-icon {
  height: 70px;
  width: 70px;
}
.forget-icon {
  height: 60px;
  width: 60px;
}
.position-auto {
  position: auto;
}
.card-type-1 {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 17.9857px 14.9881px 16.9865px rgba(0, 0, 0, 0.25);
  border-radius: 25.9793px;
}
.container-option-dropdown {
  min-width: 161px;
  min-height: 97px;
  background: rgba(176, 234, 243, 0.3);
  backdrop-filter: blur(21.5px);
  border-radius: 17px;
  right :17px;
  z-index: 2;
}

.word-wrap{
  word-wrap: break-word;
}    
.text-ellipsis{
//   overflow: hidden;
//   display: -webkit-box;
//   -webkit-line-clamp: 3;
//   -webkit-box-orient: vertical;
//   // line-height: 52px !important;
  // text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;  
overflow: hidden;
}